<template>
  <div>
    <div class="flex flex-row w-full">
      <div class="w-1/2">
        <p
          class="text-xl font-medium font-headers mb-3 font-bold text-grey-dark-2"
        >
          <span class="text-grey-medium-2 font-normal">
            Companies / {{ currentCompany.name }} /
          </span>
          Assessments
        </p>
      </div>
      <div class="flex w-1/2 justify-end">
        <Button
          text="Open as Company Admin"
          type="primary-white"
          size="medium"
          @click.native="doImpersonate"
          data-testid="impersonate-button"
        />
      </div>
    </div>
    <div class="bg-white px-2 pt-3 pb-4 rounded shadow">
      <p class="mb-2 font-bold font-header text-grey-light text-mdl">
        Upcoming and Ongoing Assessments
      </p>
      <div class="divide-y-2 divide-grey-light-1 px-2">
        <div />
        <div v-for="(assessment, index) in activeAssessments" :key="index">
          <CompanyAssessmentCard :assessment="assessment" />
        </div>
        <div />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CompanyAssessmentCard from "../components/CompanyAssessmentCard.vue";

export default {
  name: "CompanyAssessmentsView",
  components: { CompanyAssessmentCard },
  async mounted() {
    await this.getActiveAssessments({ organizationId: this.currentCompany.id });
    this.setShouldEdit(false);
  },
  computed: {
    ...mapState({
      currentCompany: (state) => state.companies.currentCompany,
      activeAssessments: (state) => state.companies.activeAssessments,
    }),
  },
  methods: {
    ...mapActions({ 
      getActiveAssessments: "companies/getActiveAssessments", 
      setShouldEdit: 'assessmentSetup/setShouldEdit',
      impersonateAdmin: 'auth/impersonateAdmin'
    }),
    doImpersonate() {
      this.impersonateAdmin({ orgId: this.currentCompany.id });
    }
  },
};
</script>
